<template>
  <div class="candidate-profile">
    <LoadingOverlay v-if="!isProfessionalInfo" />
    <div v-else class="professional-info">
      <div class="cardError">
        <div
          v-if="cardError"
          class="pb-1 cardError"
          :class="cardHasError ? 'card-error-msg' : ''"
        >
          {{ cardHasError }}
        </div>
      </div>
      <CCard
        class="has-loading-overlay"
        :class="cardHasError ? 'card-has-error' : ''"
      >
        <CCardBody>
          <CRow>
            <div class="col-md-12">
              <div class="d-flex justify-content-between">
                <div class="mt-2">
                  <h5 class="main-title pb-3">Professional Information</h5>
                </div>
                <div class="d-flex justify-content-end">
                  <button
                    name="save"
                    class="hand-icon action-button"
                    v-c-tooltip="{ content: 'Save', placement: 'left' }"
                    @click="updateProfessionalInfo"
                  >
                    <CIcon
                      name="cil-save"
                      class="active"
                      style="margin-right: 20px"
                    />
                  </button>
                  <button
                    name="cancel"
                    class="hand-icon action-button ml-2"
                    v-if="getMultiFormEdited['personalInformation']"
                    v-c-tooltip="{ content: 'Cancel', placment: 'left' }"
                    @click="resetProfessionalData(), clearFields()"
                  >
                    <CIcon name="cil-x-circle" style="margin-right: 20px" />
                  </button>
                </div>
              </div>
              <ValidationObserver ref="professional" v-slot="{ handleSubmit }">
                <form
                  id="professional"
                  @submit.prevent="handleSubmit(onSubmitStep1)"
                >
                  <fieldset :disabled="isDisabled">
                    <CRow>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_candidate_type_id"
                            class="required col-lg-12 col-md-12"
                            >{{ !isStudent ? "Type" : "Profession or Field of Study" }}
                            <!-- <span
                              class="hide-small-screen"
                              style="cursor: pointer; color: #e55353"
                            >
                              <i
                                class="fa fa-exclamation-triangle fa-lg"
                                @click="showConfirmModal()"
                              ></i>
                            </span> -->
                          </label>
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                :disabled="disabled"
                                name="candidate_type_id"
                                :value="profile.candidate_type_id"
                                :options="
                                  options && options['candidateType']
                                    ? options['candidateType']
                                    : []
                                "
                                @input="handleChangeSelect"
                                :taggable="false"
                                :multiple="false"
                                :clearable="false"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <!-- <div class="hide-large-screen">
                        <CCol md="1" class="pl-1 pr-2">
                          <CRow class="row mb-4 pl-1 pt-2">
                            <span
                              class="pt-4 pl-1"
                              style="cursor: pointer; color: #e55353"
                            >
                              <i
                                class="fa fa-exclamation-triangle fa-lg"
                                @click="showConfirmModal()"
                              ></i>
                            </span>
                          </CRow>
                        </CCol>
                      </div> -->
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_current_job"
                            class="col-lg-12 col-md-12"
                            >Current Job Title / Role</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <TextInput
                              name="current_job"
                              :value="profile.current_job"
                              @change="handleInput"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <!-- <CRow v-if="isNurse && isStudent">
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            class="col-lg-12 col-md-12"
                            >Where are you studying?</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <Select
                              name="student_country_id"
                              :value="studentProfile.student_country_id"
                              :options="(options && options['locationInfo']) || []"
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                              :disabled="true"
                            />
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="training_area"
                            class="col-lg-12 col-md-12"
                            >Training Area</label>
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <Select
                            name="student_speciality_id"
                            :value="studentProfile.student_speciality_id"
                            :options="(options && options['studentCourseSpeciality']) || []"
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                            :disabled="true"
                          />
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="course_level"
                            class="col-lg-12 col-md-12"
                            >Course Level</label>
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <Select
                            name="student_qualification_id"
                            :value="studentProfile.student_qualification_id"
                            :options="(options && options['course_qualifications']) || []"
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                            :disabled="true"
                          />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow> -->
                    <CRow v-if="isNurse && !isStudent">
                      <CCol lg="6" md="6" sm="12">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_license_country_id"
                            class="col-lg-12 col-md-12"
                            >Licensed to Pratice In</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <Select
                              name="licensing_country_id"
                              :value="profile.licensing_country_id"
                              @input="handleChangeSelect"
                              :options="
                                options && options['locationInfo']
                                  ? options['locationInfo']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                            />
                          </div>
                        </CRow>
                      </CCol>
                      <CCol lg="6" md="6" sm="12">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_license_level_id"
                            class="col-lg-12 col-md-12"
                            >Licensed to Practice as</label
                          >
                          <div class="col infoicon-multi-select">
                            <Select
                              name="license_level_id"
                              :value="profile.license_level_id"
                              @input="handleChangeSelect"
                              :options="
                                options &&
                                options['candidateLicenseLevelByCountry']
                                  ? options['candidateLicenseLevelByCountry']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6" v-if="(isNurse || isNonClinicAdmin) && !isStudent">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_nursing_level"
                            class="col-lg-12 col-md-12 required"
                            >{{
                              isNurse ? `Nurse / Midwifery Level` : `Level`
                            }}</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                name="level_id"
                                :value="profile.level_id"
                                :options="
                                  options && options['candidateLevel']
                                    ? options['candidateLevel']
                                    : []
                                "
                                @input="handleChangeSelect"
                                :taggable="false"
                                :multiple="false"
                                :clearable="false"
                                :error="errors[0]"
                                :showTooltip="true"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol v-if="isNonClinicAdmin" md="6">
                        <CRow class="row mb-3">
                          <label class="col-lg-12 col-md-12">
                            Years in Role (<b
                              v-if="profile.years_at_level != null"
                              >{{ profile.years_at_level + " " }}</b
                            >yrs)
                          </label>
                          <div class="ml-1 mr-1 col-lg-12 col-md-12 col-sm-12">
                            <v-slider
                              name="years_at_level"
                              :min="0"
                              :max="20"
                              :value="profile.years_at_level"
                              v-model="profile.years_at_level"
                              @change="handleyrsSlider"
                            ></v-slider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol
                        md="6"
                        v-if="isNurse && !isOnlyNursingQualification && !isStudent"
                      >
                        <CRow name="registered_as" class="row mb-3">
                          <label class="required col-lg-12 col-md-12"
                            >Are you registered as a</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="d-flex justify-content-between">
                              <label
                                >Speciality Nurse or a Nurse(Advanced Nurse)
                                Practitioner?</label
                              >
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <RadioButton
                                  class="text-nowrap"
                                  name="speciality_nurse_or_nurse_practitioner"
                                  :value="
                                    profile.speciality_nurse_or_nurse_practitioner
                                  "
                                  :options="
                                    options && options['boolean']
                                      ? options['boolean']
                                      : []
                                  "
                                  @change="handleChangeRadioNurseType"
                                  :error="errors[0]"
                                />
                              </ValidationProvider>
                            </div>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol v-if="isNurse && !isStudent">
                        <CRow class="row mb-3">
                          <label class="col-lg-12 col-md-12"
                            >Have you worked as a UK Registered Nurse?</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <RadioButton
                              name="is_uk_regd_nurse"
                              :value="profile.is_uk_regd_nurse"
                              :options="
                                options && options['booleanUk']
                                  ? options['booleanUk']
                                  : []
                              "
                              @change="handleChangeRadio"
                            />
                          </div>
                        </CRow>
                      </CCol>
                      <CCol
                        md="6"
                        v-if="isNurse && (profile.is_uk_regd_nurse || band_id)"
                      >
                        <CRow>
                          <label class="col-md-12 col-lg-12"
                            >Current NHS Band</label
                          >
                          <CCol md="12">
                            <Select
                              name="band_id"
                              :value="band_id"
                              :options="
                                options && options['candidateBands']
                                  ? options['candidateBands']
                                  : []
                              "
                              @input="handleChangeSelectCustom"
                              :clearable="false"
                            />
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </fieldset>
                </form>
              </ValidationObserver>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
    <QualificationInfoNurse
      v-if="isNurse || isAHP"
      :isNurse="isNurse"
      :isAHP="isAHP"
      @tooltipHide="tooltipHide"
    >
    </QualificationInfoNurse>
    <QualificationInfo
      v-else
      :candidateTypeId="profile.candidate_type_id.code"
      @tooltipHide="tooltipHide"
    >
    </QualificationInfo>
    <SpecializationInfoNurse
      v-if="isNurse || isAHP || isNonClinicAdmin"
      ref="speciality"
      class="specialization"
      :candidateTypeId="profile.candidate_type_id.code"
      :currentJob="profile.current_job"
      :cancel="cancel"
      @tooltipHide="tooltipHide"
    >
    </SpecializationInfoNurse>
    <SpecializationInfo
      v-else-if="!isNurse && !isAHP"
      ref="speciality"
      class="specialization"
      :candidateTypeId="profile.candidate_type_id.code"
      :currentJob="profile.current_job"
      :cancel="cancel"
      @tooltipHide="tooltipHide"
    >
    </SpecializationInfo>
    <SpecialInterestInfo v-if="!isAHP"> </SpecialInterestInfo>
    <ExperienceInfo
      v-if="isNonClinicAdmin"
      :candidateTypeId="profile.candidate_type_id.code"
      @tooltipHide="tooltipHide"
    />
    <LicenseInfo v-if="!isNonClinicAdmin"> </LicenseInfo>
    <Modal
      :modalTitle="confirmModal.modalTitle"
      :modalColor="confirmModal.modalColor"
      :modalContent="confirmModal.modalContent"
      :isShowPopup="confirmModal.isShowPopup"
      :buttons="confirmModal.buttons"
      :modalCallBack="confirmModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>

<script>
import Vue from "vue";
import ProfileSection from "./ProfileSection";
import { mapActions, mapGetters } from "vuex";
import { extend, validate } from "vee-validate";
import QualificationInfo from "@/containers/CandidateProfile/QualificationInfoTalentFind";
import ExperienceInfo from "@/containers/CandidateProfile/ExperienceInfoTalentFind";
import LicenseInfo from "@/containers/CandidateProfile/LicenseInfoTalentfind";
import SpecialInterestInfo from "@/containers/CandidateProfile/SpecialInterestInfoTFind";
import SpecializationInfo from "@/containers/CandidateProfile/SpecializationInfoTalentFind";
import PreferredDepartment from "@/containers/CandidateProfile/PreferredDepartment";
import Modal from "@/components/reusable/Modal";
import { required } from "vee-validate/dist/rules";
import SpecializationInfoNurse from "@/containers/CandidateProfile/SpecializationInfoTalentFindNurse";
import QualificationInfoNurse from "@/containers/CandidateProfile/QualificationInfoTalentFindNurse";
extend("required", { ...required, message: "This field is required" });
import _ from "lodash";
const debounce = require("lodash.debounce");
import { isEmptyObjectCheck } from "../../helpers/helper";

export default {
  mixins: [ProfileSection],
  components: {
    LicenseInfo,
    SpecialInterestInfo,
    SpecializationInfo,
    QualificationInfo,
    Modal,
    PreferredDepartment,
    SpecializationInfoNurse,
    QualificationInfoNurse,
    ExperienceInfo,
  },
  data() {
    return {
      isDisabled: false,
      payload: {},
      profile: {},
      errors: [],
      cardHasError: "",
      cancel: false,
      confirmModal: {
        modalColor: "primary",
        modalTitle: "NOTIFICATION",
        modalContent:
          "Are you sure to update the Profession? This will reset your Qualification and Speciality!",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      disabled: true,
      band_id: null,
      studentProfile:{}
    };
  },
  computed: {
    ...mapGetters([
      "uploaded",
      "candidateProfile",
      "candidateLevelFilterByTypeCountry",
      "nurseRegistrationType",
      "locationCountryInfo",
      "getCandidateQualification",
      "getCandidateBands",
      "getCourseSpeciality",
      "getCourseQualifications",
    ]),
    options() {
      return {
        candidateType: this.candidateTypeReOrder || [],
        speciality_id:
          this.specialityFilterByType({
            candidateType: this.profile?.candidate_type_id?.code,
          }) || [],
        sub_speciality_id:
          this.subSpecialityFilterBySpeciality({
            speciality: this.profile?.speciality_id?.code,
          }) || [],
        candidateLevel:
          this.candidateLevelFilterByTypeCountry({
            candidateType: this.profile?.candidate_type_id?.code,
            country_id: null,
          }) || [],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        booleanUk: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        locationInfo: this.locationCountryInfo || [],
        candidateLicenseLevelByCountry:
          this.candidateLevelFilterByTypeCountry({
            candidateType: this.profile?.candidate_type_id?.code,
            country_id: this.profile?.licensing_country_id?.code,
            is_licensing: true,
          }) || [],
        candidateBands: this.getCandidateBands || [],
        locationInfo: this.locationCountryInfo || [],
        studentCourseSpeciality: this.getCourseSpeciality || [],
        course_qualifications: this.getCourseQualifications || [],
      };
    },
    candidateTypeReOrder() {
      let sortBy = [1, 2, 8].reverse();
      return (
        this.candidateType &&
        this.candidateType.length &&
        this.candidateType.sort((a, b) => {
          return -sortBy.indexOf(a.code) - -sortBy.indexOf(b.code);
        })
      );
    },
    isProfessionalInfo() {
      if (Object.keys(this.candidateProfile).length) {
        return this.setProfessionalData(this.candidateProfile);
      }
      return false;
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.personalInformation
      ) {
        this.cardHasError = "Please Save or Delete";
        if (this.getMultiFormEdited.fieldValidateError) {
          this.cardHasError = "Please fill mandatory fields";
        }
        let el = this.$el.getElementsByClassName("cardError")[0];
        el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
    isNurse() {
      return this.profile?.candidate_type_id?.code
        ? this.profile.candidate_type_id.code === 2
        : false;
    },
    isNonClinicAdmin() {
      return this.profile?.candidate_type_id?.code
        ? this.profile.candidate_type_id.code === 6
        : false;
    },
    isAHP() {
      return this.profile?.candidate_type_id?.code === 3 || false;
    },
    nurseTypes() {
      return (
        this.nurseRegistrationType
          .filter(
            (val) => val != "speciality_nurse" && val != "nurse_practitioner"
          )
          .map((val) => ({
            code: val,
            label:
              val && val == "any_higherqualification_related_to_practice"
                ? "Higher Qualifications are related to Speciality or Nurse Practitioner practice"
                : _.startCase(val),
          })) || []
      );
    },
    isOnlyNursingQualification() {
      return (
        (this.getCandidateQualification.length === 1 &&
          [423, 425].includes(
            this.getCandidateQualification[0].qualification_id
          )) ||
        false
      );
    },
    isStudent() {
      return this.candidateProfile?.is_student;
    },
    studentProfessionalDetails(){
      if(this.candidateProfile.candidate_type_id === 2){
        this.fetchCourse(this.candidateProfile.candidate_type_id),
        this.fetchSpeciality(this.candidateProfile.candidate_type_id)
      }
      if (this.candidateProfile?.qualifications) {
        this.profile = {
          student_country_id: this.candidateProfile?.qualifications?.[0]?.country_id,
          student_speciality_id: this.candidateProfile?.qualifications?.[0]?.speciality_id,
          student_qualification_id: this.candidateProfile?.qualifications?.[0]?.qualification_id
        };

        // filter based on getting id's
        const filteredCountry = this.options?.locationInfo?.find(country => country.code === this.profile?.student_country_id);
        const filteredQualifications = this.options?.course_qualifications?.filter(qual => qual.code === this.profile?.student_qualification_id);
        const filteredSpeciality = this.options?.studentCourseSpeciality?.filter(spec => spec.code === this.profile?.student_speciality_id);

        // student candidate contry
        if (filteredCountry) {
          const countryValue = {
            code: filteredCountry.code,
            label: filteredCountry.country_name
          };
          this.handleChangeSelectInput('student_country_id', countryValue);
        }
        
        // student candidate qualification
        if (filteredQualifications.length > 0) {
          const qualificationValue = {
            code: filteredQualifications[0].code,
            label: filteredQualifications[0].label
          };

          this.handleChangeSelectInput('student_qualification_id', qualificationValue);
        }

        // student candidate qualification
        if (filteredSpeciality.length > 0) {
          const specialityValue = {
            code: filteredSpeciality[0].code,
            label: filteredSpeciality[0].label
          };

          this.handleChangeSelectInput('student_speciality_id', specialityValue);
        }
      }
    }
  },
  async mounted() {
    const isValid = this.$refs.professional.validate().then((res) => {
      res ? null : this.multiFormEdited({ fieldValidateError: true });
    });
    this.studentProfessionalDetails;
  },
  methods: {
    ...mapActions([
      "updateCandidate",
       "showToast",
       "fetchCourseSpeciality",
       "fetchCourseQualification",
      ]),
    setProfessionalData(profileData) {
      this.profile = {
        customer_uid: profileData.customer_uid,
        candidate_uid: profileData.candidate_uid,
        is_uk_regd_nurse: profileData?.is_uk_regd_nurse || false,
        candidate_type_id:
          profileData.candidate_type !== null
            ? {
                code: profileData.candidate_type.candidate_type_id,
                label: profileData.candidate_type.candidate_type,
              }
            : null,
        speciality_id:
          profileData.speciality !== null
            ? {
                code: profileData.speciality.speciality_id,
                label: profileData.speciality.speciality,
              }
            : null,
        sub_speciality_id:
          profileData.sub_speciality_id !== null
            ? {
                code: profileData.sub_speciality.sub_speciality_id
                  ? profileData.sub_speciality.sub_speciality_id
                  : null,
                label: profileData.sub_speciality.sub_speciality
                  ? profileData.sub_speciality.sub_speciality
                  : null,
              }
            : null,
        level_id:
          profileData.level_id !== null
            ? {
                code: profileData.level_id ? profileData.level_id : null,
                label: profileData.candidate_level
                  ? profileData.candidate_level?.candidate_level
                  : null,
              }
            : null,
        current_job: profileData.current_job,
        years_at_level: profileData.years_at_level,
        licensing_country_id:
          profileData.license_country_id !== null
            ? this.locationCountryInfo?.filter(
                ({ code }) => profileData.license_country_id == code
              )[0]
            : null,
        license_level_id:
          profileData.license_level_id !== null
            ? this.candidateLevelFilterByTypeCountry({
                candidateType: profileData?.candidate_type_id,
                country_id: profileData?.license_country_id,
                is_licensing: true,
              })?.filter(({ code }) => profileData.license_level_id == code)[0]
            : null,
      };
      if (profileData.candidate_type_id === 2) {
        this.profile["speciality_nurse_or_nurse_practitioner"] = false;
        profileData?.nurse_registration_type &&
          profileData?.nurse_registration_type.forEach((element) => {
            this.profile[element] = true;
          });
      }
      if (profileData.candidate_type_id === 2) {
        this.profile["level_id"] = profileData?.candidate_level?.level_id
          ? {
              code: profileData?.candidate_level?.level_id,
              label: profileData?.candidate_level?.candidate_level,
            }
          : null;
        this.band_id = profileData?.band
          ? {
              code: profileData?.band?.band_id,
              label: profileData?.band?.band_name,
            }
          : null;
      }
      return this.profile;
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 100);
    },
    async updateProfessionalInfo() {
      this.$emit("tooltipHide");
      const isValid = await this.$refs.professional.validate();
      if (!isValid) {
        this.cardHasError = "Please fill mandatory fields";
        return;
      }
      this.payload = {
        candidate_uid: this.profile?.candidate_uid,
        candidate_type_id: this.profile?.candidate_type_id?.code,
        volunteer: this.profile?.volunteer,
        current_job: this.profile?.current_job,
        years_at_level: this.profile?.years_at_level,
        is_uk_regd_nurse: this.profile?.is_uk_regd_nurse,
      };
      if (this.profile?.candidate_type_id?.code === 2) {
        let nurse_registration_type = [];
        if (
          this.candidateProfile?.nurse_registration_type &&
          this.candidateProfile?.nurse_registration_type.length &&
          this.candidateProfile?.nurse_registration_type.includes(
            "any_higherqualification_related_to_practice"
          )
        ) {
          nurse_registration_type = [
            ...nurse_registration_type,
            "any_higherqualification_related_to_practice",
          ];
        }
        if (this.profile["speciality_nurse_or_nurse_practitioner"]) {
          nurse_registration_type = [
            ...nurse_registration_type,
            "speciality_nurse_or_nurse_practitioner",
          ];
        }
        nurse_registration_type = nurse_registration_type.length
          ? nurse_registration_type
          : null;
        this.payload = {
          ...this.payload,
          license_country_id: this.profile?.licensing_country_id?.code,
          license_level_id: this.profile?.license_level_id?.code,
          nurse_registration_type,
        };
      }
      if (
        this.profile?.candidate_type_id?.code === 2 ||
        this.isNonClinicAdmin
      ) {
        this.payload = {
          ...this.payload,
          level_id: this.profile?.level_id?.code,
          band_id: this.band_id?.code || null,
        };
      }
      if (this.isNurse && this.payload?.is_uk_regd_nurse === false) {
        this.payload = {
          ...this.payload,
          band_id: null,
        };
      }
      this.saveProfessionalInfo(this.payload);
    },
    saveProfessionalInfo(data) {
      const { customer_uid } = this.profile;
      let appendAction = this.updateCandidate({
        data,
        customer_uid,
      });
      Promise.all([appendAction]).then((res) => {
        this.clearFields();
      });
    },
    resetProfessionalData() {
      this.cancel = true;
      this.setProfessionalData(this.candidateProfile);
    },
    clearFields() {
      this.cardHasError = "";
      this.disabled = true;
      this.multiFormEdited({ personalInformation: false });
      this.multiFormEdited({ fieldValidateError: false });
      this.multiFormEdited({ showError: false });
      this.$emit("tooltipHide");
    },
    showConfirmModal() {
      this.confirmModal.isShowPopup = true;
    },
    confirmModalCallBack(action) {
      if (action === "Confirm") {
        this.disabled = false;
      }
      if (action === "Cancel") {
        this.disabled = true;
      }
      this.confirmModal.isShowPopup = false;
    },
    handleChangeRadioNurseType(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }

      Vue.set(this.profile, name, val);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
      this.formEdited(name);
    },
    handleyrsSlider: debounce(function (value) {
      Vue.set(this.profile, "years_at_level", value);
      Vue.set(this.payload, "years_at_level", value);
      this.multiFormEdited({ personalInformation: true });
    }, 500),
    handleChangeSelectCustom(name, value) {
      this.$nextTick(() => {
        this.band_id = value;
        this.multiFormEdited({ personalInformation: true });
        this.formEdited(name);
      });
    },
    handleChangeSelectInput(name, value) {
      Vue.set(this.studentProfile, name, value);
    },
    fetchCourse(id) {
      if ([1, 4].includes(id)) {
        this.fetchCourseQualification({
          candidate_type_id: id,
          qualification_classification: 'First Degree'});
      }
      else if([2, 3].includes(id)) {
        this.fetchCourseQualification({
          candidate_type_id: id,
          qualification_classification: ['First Degree','Diploma']});
      }
    },
    fetchSpeciality(id) {
      if(id === 2) {
        this.fetchCourseSpeciality({
          candidate_type_id: id,
          is_training: true});
      }
      else if(id === 3) {
        this.fetchCourseSpeciality({
          candidate_type_id: id});
      }
    }
  },
  watch: {
    nurseRegistrationType() {
      this.nurseRegistrationType.forEach((e) => {
        this.profile[e] ? null : (this.profile[e] = false);
      });
    },
  },
};
</script>
<template>
  <div class="candidate-profile">
    <LoadingOverlay v-if="!isSpecializationInfo" />
    <div v-else class="preferredDept-info">
      <div v-if="cardError"></div>
      <div class="pb-1" :class="cardHasError ? 'card-error-msg' : ''">
        {{ cardHasError }}
      </div>
      <CCard
        class="mt-2 has-loading-overlay"
        :class="cardHasError ? 'card-has-error' : ''"
      >
        <CCardBody>
          <div class="col-md-12">
            <div class="d-flex justify-content-between">
              <div class="mt-2">
                <h5 class="main-title pb-3">
                  Preferred Departments
                  <span class="h6">
                    [{{ preferredDepartments.length || "0" }} record{{
                      preferredDepartments.length > 1 ? "s" : ""
                    }}]</span
                  >
                </h5>
              </div>
            </div>
            <ValidationObserver ref="preferredDept" v-slot="{ handleSubmit }">
              <form id="preferredDept" @submit.prevent="handleSubmit()">
                <fieldset :disabled="isDisabled">
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_department_id"
                          class="col-lg-12 col-md-12 required"
                          >Department</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="department_id"
                              :value="profile.department_id"
                              @input="handleChangeSelect"
                              :options="
                                options && options['departments']
                                  ? options['departments']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                              :disabled="isDisabled"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_nurse_yrs_exp"
                          class="col-lg-12 col-md-12"
                          >Number of Years Experience({{
                            profile.experience_in_yrs || 0
                          }}
                          yrs)</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <v-slider
                            name="experience_in_yrs"
                            :min="0"
                            :max="10"
                            :value="profile.experience_in_yrs"
                            v-model="profile.experience_in_yrs"
                            @change="handleSliderYrsExp"
                          ></v-slider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow name="bed_size" class="row">
                        <label class="col-lg-12 col-md-12">Bed size</label>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="numeric"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="bed_size"
                              :value="profile.bed_size"
                              @input="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow>
                        <label class="col-lg-12 col-md-12">
                          Date of Last Practice
                        </label>
                        <div class="col-lg-12 col-md-126 col-sm-12">
                          <ValidationProvider
                            name="dob"
                            rules="date_validate|no_future_date"
                            v-slot="{ errors }"
                          >
                            <DateInput
                              :onlyMonthAndYear="false"
                              name="last_date_of_practice"
                              :value="profile.last_date_of_practice"
                              @input="handleDatePickerChange"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row">
                        <label class="col-lg-12 col-md-12">Current</label>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <RadioButton
                            name="is_current"
                            :value="profile.is_current"
                            :options="
                              options && options['boolean']
                                ? options['boolean']
                                : []
                            "
                            @change="handleChangeRadio"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow class="mt-2">
                    <CCol md="6"></CCol>
                    <CCol md="6">
                      <div class="float-right mb-2">
                        <CButton
                          v-if="
                            !edit_preferred_departments_id &&
                            getMultiFormEdited['preferredDeptInformation']
                          "
                          class="btn-primary small mr-2"
                          style="height: 28px"
                          @click="updatePreferredDepInfo"
                          v-c-tooltip="
                            'Click to Add multiple records!'
                          "
                          >Add</CButton
                        >
                        <CButton
                          class="btn-primary small mr-1"
                          style="height: 28px"
                          @click="updatePreferredDepInfo"
                          v-if="edit_preferred_departments_id"
                          v-c-tooltip="
                            'Click to update the record!'
                          "
                          >Update</CButton
                        >
                        <button
                          name="cancel"
                          class="hand-icon action-button"
                          v-c-tooltip="'Cancel'"
                          v-if="
                            edit_preferred_departments_id ||
                            getMultiFormEdited['preferredDeptInformation']
                          "
                          @click="discard()"
                        >
                          <CIcon
                            name="cil-x-circle"
                            style="margin-right: 20px"
                          />
                        </button>
                      </div>
                    </CCol>
                  </CRow>
                  <div class="multi-records">
                    <div
                      class="top-border d-flex justify-content-between"
                      v-for="(data, index) in preferredDepartments"
                      :key="index"
                    >
                      <div class="ml-2 mt-1 record">
                        <div class="widget-content-actions hide-large-screen">
                          <CButton
                            class="icon icon-edit mr-2"
                            v-if="
                              data && data.candidate_preferred_departments_id
                            "
                            @click="editPreferredDepartment(data)"
                          >
                          </CButton>
                          <CButton
                            class="icon mr-1"
                            v-if="
                              data && data.candidate_preferred_departments_id
                            "
                            @click="deletePreferredDepartmentInfo(data)"
                          >
                            <i class="fas fa-trash-alt" />
                          </CButton>
                        </div>
                        <CRow
                          class="d-flex"
                          style="
                            justify-content: space-between;
                            margin-left: 5px;
                          "
                        >
                          <div class="table col col-4">
                            <p class="head">
                              {{
                                data.is_current
                                  ? `Main/Current Department`
                                  : `Other Departments work in`
                              }}
                            </p>
                            <p class="data">
                              {{ data.department.department_name || "--" }}
                            </p>
                          </div>
                          <div class="table col col-4">
                            <p class="head">Years of Experience</p>
                            <p class="data">
                              {{ data.experience_in_yrs || "--" }}
                            </p>
                          </div>
                          <div class="table col col-4">
                            <p class="head">Bed Size</p>
                            <p class="data">{{ data.bed_size || "--" }}</p>
                          </div>
                          <div class="table col col-4">
                            <p class="head">Date of Last Practice</p>
                            <p class="data">
                              {{ data.last_date_of_practice | dateFormat }}
                            </p>
                          </div>
                        </CRow>
                      </div>
                      <div class="hide-small-screen">
                        <div
                          class="widget-content-actions d-flex flex-column justify-content-center"
                        >
                          <CButton
                            class="icon icon-pencil-alt mb-2"
                            v-if="
                              data && data.candidate_preferred_departments_id
                            "
                            @click="editPreferredDepartment(data)"
                          >
                          </CButton>
                          <CButton
                            class="icon"
                            v-if="
                              data && data.candidate_preferred_departments_id
                            "
                            @click="deletePreferredDepartmentInfo(data)"
                          >
                            <i class="fas fa-trash-alt" />
                          </CButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </ValidationObserver>
          </div>
        </CCardBody>
      </CCard>
    </div>
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deletemodalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import ProfileSection from "./ProfileSection";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import m from "moment";
import { extend } from "vee-validate";
const debounce = require("lodash.debounce");
import { numeric } from "vee-validate/dist/rules";
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("no_future_date", (value) => {
  let input_date = new Date(value);
  let today_date = new Date();
  if (input_date > today_date) {
    return "Given date should not be greater than today!";
  }
  return true;
});
import Modal from "@/components/reusable/Modal";
export default {
  mixins: [ProfileSection],
  components: {
    Modal,
  },
  data() {
    return {
      isDisabled: false,
      payload: {},
      profile: {},
      errors: [],
      cardHasError: "",
      edit_preferred_departments_id: null,
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        candidate_preferred_departments_id: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getDepartmentsByOrganisationId",
      "candidateProfile",
      "preferredDepartments",
    ]),
    organisationID() {
      return this.candidateProfile?.organisation_id || null;
    },
    options() {
      return {
        departments: this.getDepartmentsByOrganisationId || [],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
      };
    },
    isSpecializationInfo() {
      return true;
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.preferredDeptInformation
      ) {
        this.cardHasError = "Please Save or Delete";
        if (this.getMultiFormEdited.fieldValidateError) {
          this.cardHasError = "Please fill mandatory fields";
        }
        let el = this.$el.getElementsByClassName("preferredDept-info")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions([
      "addPreferredDepartment",
      "fetchPreferredDepartments",
      "updatePreferredDepartment",
      "deletePreferredDepartment",
    ]),
    clearFields() {
      this.$nextTick(() => {
        this.$refs.preferredDept.reset();
        this.$refs.preferredDept.errors.clear;
      });
      this.cardHasError = "";
      this.multiFormEdited({ preferredDeptInformation: false });
      this.multiFormEdited({ fieldValidateError: false });
      this.multiFormEdited({ showError: false });
      this.$emit("tooltipHide");
    },
    async updatePreferredDepInfo() {
      this.$emit("tooltipHide");
      const isValid = await this.$refs.preferredDept.validate();
      if (!isValid) {
        this.cardHasError = "Please fill mandatory fields";
        return;
      } else {
        let hasError = false;
        if (this.edit_preferred_departments_id === null) {
          hasError = this.preferredDepartments.some(
            (e) => e?.department_id === this.profile?.department_id?.code
          );
        }
        if (!hasError) {
          let finalPayload = {
            candidate_uid: this.candidateProfile?.candidate_uid,
            department_id: this.profile?.department_id?.code,
            experience_in_yrs: this.profile?.experience_in_yrs || null,
            last_date_of_practice: this.profile?.last_date_of_practice,
            is_current: this.profile?.is_current,
            bed_size: this.profile?.bed_size,
          };
          this.savePreferredDepartment(finalPayload);
        } else this.cardHasError = "Preferred Department Already Exists!";
      }
    },
    discard() {
      this.profile = {};
      this.edit_preferred_departments_id = null;
      this.clearFields();
    },
    handleSliderYrsExp: debounce(function (value) {
      Vue.set(this.profile, "experience_in_yrs", value);
      Vue.set(this.payload, "experience_in_yrs", value);
    }, 500),
    savePreferredDepartment(payload) {
      let appendAction;
      if (this.edit_preferred_departments_id === null) {
        appendAction = this.addPreferredDepartment(payload);
      } else {
        let finalPayload = {
          candidate_preferred_departments_id: this
            .edit_preferred_departments_id,
          payload,
        };
        appendAction = this.updatePreferredDepartment(finalPayload);
      }
      Promise.all([appendAction]).then((res) => {
        this.discard();
        this.fetchPreferredDepartments();
      });
    },
    editPreferredDepartment(data) {
      let el = this.$el.getElementsByClassName("preferredDept-info")[0];
      el.scrollIntoView(true);
      window.scrollBy(0, -200);
      this.profile = {
        department_id: data?.department
          ? {
              code: data.department.department_id,
              label: data.department.department_name,
            }
          : null,
        experience_in_yrs: data?.experience_in_yrs,
        bed_size: data?.bed_size,
        last_date_of_practice: data?.last_date_of_practice,
        is_current: data?.is_current,
      };
      this.edit_preferred_departments_id =
        data?.candidate_preferred_departments_id;
    },
    deletePreferredDepartmentInfo(data) {
      this.$emit("tooltipHide");
      this.deleteConfirmModal.buttons = ["Cancel", "Confirm"];
      this.deleteConfirmModal.modalContent = `Do you want to delete preferred department - ${data.department.department_name} ?`;
      this.deleteConfirmModal.candidate_preferred_departments_id =
        data.candidate_preferred_departments_id;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deletemodalCallBack(action) {
      let appendAction;
      if (action === "Confirm") {
        this.deletePreferredDepartment(
          (appendAction = this.deleteConfirmModal
            .candidate_preferred_departments_id)
        );
      }
      Promise.all([appendAction]).then((res) => {
        this.discard();
        this.deleteConfirmModal.isShowPopup = false;
        this.deleteConfirmModal.candidate_preferred_departments_id = null;
      });
    },
  },
  filters: {
    dateFormat(data) {
      if (data != null) {
        return m(data).format("DD MMM YYYYY");
      }
      return "--";
    },
  },
};
</script>